import React, { useMemo } from "react";
import { GoogleMap } from "@react-google-maps/api";
// import { GoogleMap, LoadScript } from "@react-google-maps/api";
// const refreshPage = () => {
//   window.location.reload(false);
// };

export const MyMapComponent = () => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const renderMap = useMemo(() => {
    const mapContainerStyle = {
      height: "300px",
      width: "100%",
    };

    const center = {
      lat: 42.395025,
      lng: -82.922345,
    };

    const mapOptions = {
      zoom: 13,
    };

    if (!apiKey) {
      return null; // Return null if API key is not available
    }

    return (
      // <LoadScript>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={mapOptions.zoom}
      >
        {/* Add map markers, polygons, or other map components here */}
      </GoogleMap>
      // </LoadScript>
    );
  }, [apiKey]);

  return <div>{renderMap}</div>;
};

export default MyMapComponent;
