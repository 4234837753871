// ReviewCard.js
import React from "react";
import anonymousUser from "../../assets/images/reviewspage/anonymousUser.png";
import { Carousel } from "react-bootstrap"; // Import Bootstrap Carousel

const ReviewCard = ({ _id, title, description, rating, images = [] }) => {
  // const imageUrl = images ? `data:image/jpeg;base64,${images}` : anonymousUser;
  const generateStars = (count) => {
    const stars = [];
    for (let i = 0; i < 5; i++) {
      stars.push(
        <svg
          key={`star-${i}`}
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill={i < count ? "#FFD700" : "#d3d3d3"} // Gold for filled, light gray for empty
          viewBox="0 0 24 24"
          stroke="black"
          strokeWidth="1"
          style={{ marginRight: "4px" }}
        >
          <path d="M12 .587l3.668 7.568L24 9.424l-5.999 5.756L19.336 24 12 19.771 4.664 24l1.335-8.82L0 9.424l8.332-1.269L12 .587z" />
        </svg>
      );
    }
    return stars;
  };
  return (
    <div
      className="review-card"
      style={{ border: "1px solid #ccc", margin: "10px", padding: "10px" }}
    >
      <Carousel className="review-card-carousel">
        {images.length > 0 ? (
          images.map((img, index) => (
            <Carousel.Item key={index}>
              <img
                className="review-card-image"
                src={img}
                alt={`Slide ${index + 1}`}
              />
            </Carousel.Item>
          ))
        ) : (
          <img
            className="review-card-image"
            src={anonymousUser}
            alt="No images available"
          />
        )}
      </Carousel>
      {/* <img
        className="review-card-image"
        src={imageUrl}
        alt={title}
        style={{ width: "100px", height: "100px", objectFit: "cover" }}
      /> */}
      <div className="review-card-content">
        <h3 className="review-card-title">{title}</h3>
        <p className="review-card-description">{description}</p>
        <div className="review-card-rating">Rating:</div>
        <div className="stars black">
          {generateStars(rating)}
          {/* <span> ({rating})</span>{" "} */}
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
