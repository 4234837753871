import { Row, Col, Card, Container } from "react-bootstrap";
import fullReplacement from "../assets/images/servicespage/fullReplacement.jpeg";
import commercialRoofing from "../assets/images/servicespage/commercialRoofing.jpeg";
import residentialRoofing from "../assets/images/servicespage/residentialRoofing.jpeg";
import epdmRoofing from "../assets/images/servicespage/epdmRubberizedRoofing.jpeg";
import torchDownRoofing from "../assets/images/servicespage/torchDownFlatRoofing.jpeg";
import generalRoofingInfo from "../assets/images/servicespage/generalRoofingInformation.png";

const ServiceCard = ({ title, description, image }) => {
  return (
    <Card
      style={{
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "12px",
        overflow: "hidden",
        marginBottom: "20px",
        marginLeft: "20px",
        marginRight: "20px",
        padding: "20px",
        cursor: "pointer",
        transition: "0.3s",
        "&:hover": {
          boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
        },
        background: "#fff",
        position: "relative",
        "&:before": {
          content: "''",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: "-1",
        },
        "&:after": {
          content: "''",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          background: "#fff",
          zIndex: "-2",
          transform: "translateY(-50%)",
          opacity: "0.8",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        },
        "&:after::before": {
          content: "''",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          background: "#333",
          zIndex: "-3",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#fff",
          fontSize: "1.5rem",
        },
        "&:hover img": {
          transform: "scale(1.1)",
        },
        "&:hover.sloganTxt": {
          opacity: "1",
        },
      }}
    >
      <Card.Img
        src={image}
        alt={title}
        style={{ height: "200px", objectFit: "cover" }}
      />
      <Card.Body>
        <Card.Title
          style={{ fontSize: "1.8rem", fontWeight: "bold", color: "#333" }}
        >
          {title}
        </Card.Title>
        <Card.Text style={{ fontSize: "1rem", color: "#666" }}>
          {description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const ServicesScreen = () => {
  const services = [
    {
      title: "Full Replacements",
      description:
        "We handle complete roof replacements for both residential and commercial properties. From tearing off old materials to installing new ones, we ensure a seamless process.",
      image: fullReplacement,
    },
    {
      title: "Residential Roofing",
      description:
        "From minor repairs to full roof installations, we cater to all your residential roofing needs, regardless of style or size.",
      image: residentialRoofing,
    },
    {
      title: "Commercial Roofing",
      description:
        "We provide professional services for businesses and large commercial properties, ensuring durability and longevity.",
      image: commercialRoofing,
    },
    {
      title: "EPDM Rubberized Roofing",
      description:
        "Ideal for flat roofing systems, EPDM offers weather resistance, durability, and energy efficiency.",
      image: epdmRoofing,
    },
    {
      title: "Torch Down Flat Roofing",
      description:
        "Torch down roofing is a durable option for flat roofs, using modified bitumen adhered with a propane torch.",
      image: torchDownRoofing,
    },
    {
      title: "General Roofing Information",
      description:
        "We educate clients on the different roofing systems, helping you make the best decision for your property.",
      image: generalRoofingInfo,
    },
  ];

  return (
    <Container fluid style={{ padding: "40px 0", backgroundColor: "#f9f9f9" }}>
      <div
        style={{
          

          gap: "20px",
          marginBottom: "50px",
          padding: "20px",
          cursor: "pointer",
          transition: "0.3s",
          "&:hover": {
            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
          },
          background: "#fff",
          borderRadius: "12px",
          overflow: "hidden",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            fontSize: "3rem",
            marginBottom: "30px",
            color: "#444",
            fontWeight: "bold",
            backdropFilter: "blur(5px)",
            background: "rgba(255, 255, 255, 0.8)",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "12px",
            padding: "20px",
            cursor: "pointer",
            transition: "0.3s",
            "&:hover": {
              boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          Our Services
        </h1>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.2rem",
            marginBottom: "50px",
            color: "#666",
            fontWeight: "bold",
            backdropFilter: "blur(5px)",
            background: "rgba(255, 255, 255, 0.8)",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "12px",
            padding: "20px",
            cursor: "pointer",
            transition: "0.3s",
            "&:hover": {
              boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
            },
          }}
        >
          Explore the comprehensive range of roofing services we offer to meet
          your residential and commercial needs.
        </p>
      </div>
      <Row xs={1} md={2} lg={3} className="g-4">
        {services.map((service, index) => (
          <Col key={index}>
            <ServiceCard
              title={service.title}
              description={service.description}
              image={service.image}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ServicesScreen;