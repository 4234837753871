import React, { useState, useEffect } from "react";
import axios from "axios";
import ReviewCard from "./ReviewCard";
import Spinner from "../Spinner";

const ReviewsList = () => {
  const [reviews, setReviews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const ITEMS_PER_PAGE = 1; // Set your desired number of reviews per page

  // Fetch reviews from the API
  const fetchReviews = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://www.pointeroofingimprovements.com/api/reviews/",
        {
          params: { page, limit: ITEMS_PER_PAGE },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data.reviews;
      setReviews(data.docs);
      setTotalPages(data.totalPages);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching reviews:", error);
      alert("Failed to fetch reviews. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch reviews whenever the current page changes
  useEffect(() => {
    fetchReviews(currentPage);
  }, [currentPage]);

  return (
    <div className="reviews-list-container" style={{ padding: "20px" }}>
      <h2 className="reviews-list-header">Reviews</h2>

      {/* Pagination Controls */}
      <div className="pagination-controls" style={{ margin: "20px 0" }}>
        <button
          className="pagination-button btn btn-danger"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1 || loading}
        >
          Previous
        </button>
        <span style={{ margin: "0 10px" }}>
          Page {currentPage} of {totalPages}
        </span>
        <button
          className="pagination-button btn btn-danger"
          onClick={() =>
            setCurrentPage((prev) => Math.min(prev + 1, totalPages))
          }
          disabled={currentPage === totalPages || loading}
        >
          Next
        </button>
      </div>

      {/* Loading Indicator */}
      {loading && <Spinner />}

      {/* Render Review Cards */}
      {!loading &&
        reviews.map((review) => <ReviewCard key={review._id} {...review} />)}
    </div>
  );
};

export default ReviewsList;
