// ReviewForm.js
import React, { useState } from "react";
import {
  Form,
  FormGroup,
  FormControl,
  FormLabel,
  Button,
} from "react-bootstrap";
import axios from "axios";
import * as Yup from "yup";
import { Navigate } from "react-router-dom";

// Use Yup for form validation

const ReviewForm = ({ onSubmit }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [rating, setRating] = useState(0);
  const [photos, setPhotos] = useState([]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
    rating: Yup.number().min(1).max(5).required("Rating is required"),
  });

  // Handle file input change and store the selected file
  const handleImageChange = (e) => {
    if (e.target.files) {
      // Limit to 3 files and convert them to an array of files
      const filesArray = Array.from(e.target.files).slice(0, 3);
      setPhotos(filesArray);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("HandleSubmit", { title, description, rating, photos });

    // Create FormData to handle file uploads
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("rating", rating);
    photos.forEach((photo, index) => formData.append(`photos`, photo));
    // if (photos) {
    //   // Append the file under the field name "photo" to match your backend route
    //   formData.append("photo", photos);
    // }

    try {
      const response = await axios.post(
        "https://www.pointeroofingimprovements.com/api/reviews/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (onSubmit) {
        onSubmit(response.data);
      }
      alert("Review submitted successfully!");
      // Reset form fields
      setTitle("");
      setDescription("");
      setRating(0);
      setPhotos([]);
      // Reset page
      window.location.reload();
    } catch (error) {
      console.error("Error submitting review: ", error);
    }
  };

  return (
    // <Form onSubmit={handleSubmit} className="form-css">
    //   <FormLabel className="form-label">Submit Your Review</FormLabel>
    //   <FormGroup>
    //     <FormLabel>Name:</FormLabel>
    //     <FormControl
    //       type="text"
    //       name="title"
    //       value={title}
    //       onChange={(e) => setTitle(e.target.value)}
    //       required
    //     />
    //   </FormGroup>
    //   <FormGroup>
    //     <FormLabel>Description:</FormLabel>
    //     <FormControl
    //       as="textarea"
    //       rows={3}
    //       name="description"
    //       value={description}
    //       onChange={(e) => setDescription(e.target.value)}
    //       required
    //     />
    //   </FormGroup>
    //   <FormGroup>
    //     <FormLabel>Rating:</FormLabel>
    //     <FormControl
    //       type="number"
    //       name="rating"
    //       value={rating}
    //       onChange={(e) => setRating(e.target.value)}
    //       min={1}
    //       max={5}
    //       required
    //     />
    //   </FormGroup>
    //   <FormGroup>
    //     <FormLabel>Upload up to 3 Images:</FormLabel>
    //     <FormControl
    //       type="file"
    //       name="photo"
    //       accept="image/*"
    //       onChange={handleImageChange}
    //       style={{
    //         backgroundColor: "transparent",
    //         color: "white",
    //       }}
    //     />
    //   </FormGroup>
    //   <Button style={{ marginTop: "10%" }} variant="danger" type="submit">
    //     Submit Review
    //   </Button>
    // </Form>
    <Form onSubmit={handleSubmit} className="form-css">
      <FormLabel className="form-label">Submit Your Review</FormLabel>
      <FormGroup>
        <FormLabel>Title:</FormLabel>
        <FormControl
          type="text"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Description:</FormLabel>
        <FormControl
          as="textarea"
          rows={3}
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Rating:</FormLabel>
        <FormControl
          type="number"
          name="rating"
          value={rating}
          onChange={(e) => setRating(e.target.value)}
          min={1}
          max={5}
          required
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Upload up to 3 images:</FormLabel>
        <FormControl
          type="file"
          name="photos"
          accept="image/*"
          multiple
          onChange={handleImageChange}
        />
      </FormGroup>
      <Button style={{ marginTop: "10%" }} variant="danger" type="submit">
        Submit Review
      </Button>
    </Form>
  );
};

export default ReviewForm;
