import React from "react";
import ReviewsList from "../components/Review/ReviewList";
import ReviewForm from "../components/Review/ReviewForm";

const ReviewsScreen = () => {
  return (
    <div>
      <ReviewsList />
      <ReviewForm />
    </div>
  );
};

export default ReviewsScreen;
