import { Card, Container, Row, Col, Button, Image } from "react-bootstrap";
import ownerImage from "../assets/images/aboutus/jonah.jpeg";

const AboutScreen = () => {
  return (
    <Container fluid style={{ padding: "50px", backgroundColor: "#f4f4f4" }}>
      <h1
        style={{
          textAlign: "center",
          fontSize: "3rem",
          marginBottom: "30px",
          color: "#333",
        }}
      >
        About Us
      </h1>
      <Row className="align-items-center" style={{ marginBottom: "50px" }}>
        <Col md={6} style={{ paddingRight: "30px" }}>
          <h2 style={{ fontSize: "2.5rem", color: "#444" }}>Meet Jonah</h2>
          <p style={{ fontSize: "1.2rem", color: "#666", marginTop: "20px" }}>
            Jonah-Dane has been in the home improvement industry since high
            school, working alongside family and eventually establishing Pointe
            Roofing Improvements. His passion for quality craftsmanship and
            aesthetics drives every project, ensuring exceptional results.
          </p>
          <p style={{ fontSize: "1.2rem", color: "#666" }}>
            Outside of work, Jonah enjoys spending time with family, playing
            guitar, and maintaining an active, healthy lifestyle. His artistic
            background brings a unique touch to every project.
          </p>
          <Button
            href="tel:+1-586-360-8447"
            variant="primary"
            style={{ marginTop: "20px" }}
          >
            Call Us for a Free Estimate
          </Button>
        </Col>
        <Col md={6}>
          <Image
            src={ownerImage}
            alt="Jonah-Dane"
            fluid
            style={{
              borderRadius: "12px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            }}
          />
        </Col>
      </Row>

      <Card
        style={{
          padding: "30px",
          borderRadius: "12px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h2 style={{ fontSize: "2rem", color: "#333" }}>Our Mission</h2>
        <p style={{ fontSize: "1.2rem", color: "#666" }}>
          At Pointe Roofing Improvements, our mission is to deliver exceptional
          roofing solutions while building trust and long-term relationships
          with our clients. We are committed to quality, integrity, and customer
          satisfaction.
        </p>
      </Card>

      <Card
        style={{
          padding: "30px",
          marginTop: "30px",
          borderRadius: "12px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h2 style={{ fontSize: "2rem", color: "#333" }}>Our Values</h2>
        <Row>
          <Col md={4} style={{ padding: "10px" }}>
            <h4 style={{ color: "#444" }}>Quality Craftsmanship</h4>
            <p style={{ fontSize: "1rem", color: "#666" }}>
              We ensure that every project is executed with attention to detail
              and precision, delivering high-quality results that stand the test
              of time.
            </p>
          </Col>
          <Col md={4} style={{ padding: "10px" }}>
            <h4 style={{ color: "#444" }}>Integrity</h4>
            <p style={{ fontSize: "1rem", color: "#666" }}>
              Honesty and transparency are at the core of our business. We
              provide clear communication and follow through on our promises.
            </p>
          </Col>
          <Col md={4} style={{ padding: "10px" }}>
            <h4 style={{ color: "#444" }}>Customer Satisfaction</h4>
            <p style={{ fontSize: "1rem", color: "#666" }}>
              Our goal is to exceed client expectations by providing exceptional
              service and support throughout the project.
            </p>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default AboutScreen;
