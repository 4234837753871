import { Card, Container, Row, Button, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import Image from "react-bootstrap/Image";
import MyMapComponent from "../components/MyMapComponent";
import Spinner from "../components/Spinner";
import ErrorComponent from "../components/ErrorComponent";
import commercialRoofing from "../assets/images/servicespage/commercialRoofing 2.jpeg";
import residentialRoofing from "../assets/images/servicespage/residentialRoofing.jpeg";
import epdmRoofing from "../assets/images/servicespage/epdmRubberizedRoofing.jpeg";
import torchDownRoofing from "../assets/images/servicespage/torchDownFlatRoofing.jpeg";
import generalRoofingInfo from "../assets/images/servicespage/generalRoofingInformation.png";
import fullReplacement from "../assets/images/servicespage/fullReplacement 2.jpeg";
import torchDownFlatRoofing from "../assets/images/servicespage/torchDownFlatRoofing 2.jpeg";
import generalRoofingInformation from "../assets/images/servicespage/mainPhoneSize.png";

// import { useState, useMemo } from "react";
// import BusinessProfile from "../components/BusinessProfile";
// import GoogleSignIn from "../components/GoogleSignIn";
// import GoogleProfile from "../components/GoogleProfile";

import { Wrapper, Status } from "@googlemaps/react-wrapper";
// import { LinkContainer } from "react-router-bootstrap";
// import PhoneCallButton from "../components/PhoneCallButton";

// ////////////////////////////////////////////
// import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
// import MyMapComponent from "../components/MyMapComponent";
// import Wrapper, { Status } from "@googlemaps/react-wrapper";
// import Spinner from "../components/Spinner";
// import ErrorComponent from "../components/ErrorComponent";

// // Image imports
// import commercialRoofing from "../assets/images/servicespage/commercialRoofing.jpeg";
// import residentialRoofing from "../assets/images/servicespage/residentialRoofing.jpeg";
// import fullReplacement from "../assets/images/servicespage/fullReplacement.jpeg";

const renderMapStatus = (status) => {
  switch (status) {
    case Status.LOADING:
      return <Spinner />;
    case Status.FAILURE:
      return <ErrorComponent />;
    case Status.SUCCESS:
      return <MyMapComponent />;
    default:
      return <MyMapComponent />;
  }
};

const ServiceHighlightCard = ({ title, image }) => (
  <Card
    className="highlight-card"
    style={{
      borderRadius: "12px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      overflow: "hidden",
    }}
  >
    <Card.Img
      variant="top"
      src={image}
      style={{ height: "200px", objectFit: "cover" }}
    />
    <Card.Body>
      <Card.Title
        style={{ fontSize: "1.5rem", fontWeight: "bold", color: "#444" }}
      >
        {title}
      </Card.Title>
      <LinkContainer to="/services">
        <Button variant="primary">Learn More</Button>
      </LinkContainer>
    </Card.Body>
  </Card>
);

const HomeScreen = () => {
  return (
    <Container fluid style={{ padding: "40px 0", backgroundColor: "#f9f9f9" }}>
      {/* Welcome Section */}
      <Card
        className="welcome-section"
        style={{
          marginBottom: "40px",
          padding: "30px",
          borderRadius: "12px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Card.Body>
          <h1 style={{ textAlign: "center", fontSize: "3rem", color: "#333" }}>
            Welcome to Pointe Roofing Improvements
          </h1>
          <p
            style={{
              textAlign: "center",
              fontSize: "1.2rem",
              color: "#666",
              marginTop: "15px",
            }}
          >
            Providing top-notch roofing services with integrity and
            professionalism. Let us bring your roofing project to life with a
            team you can trust.
          </p>
        </Card.Body>
      </Card>

      {/* Highlighted Services Section */}
      <h2
        style={{
          textAlign: "center",
          fontSize: "2.5rem",
          marginBottom: "30px",
          color: "#444",
        }}
      >
        Our Featured Services
      </h2>
      <Row xs={1} md={2} lg={3} className="g-4">
        <Col>
          <ServiceHighlightCard
            title="Commercial Roofing"
            image={commercialRoofing}
          />
        </Col>
        <Col>
          <ServiceHighlightCard
            title="Residential Roofing"
            image={residentialRoofing}
          />
        </Col>
        <Col>
          <ServiceHighlightCard
            title="Full Replacements"
            image={fullReplacement}
          />
        </Col>
      </Row>

      {/* About Us Section */}
      <Card
        className="about-us-section"
        style={{
          marginTop: "50px",
          padding: "30px",
          borderRadius: "12px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Row>
          <Col md={6} style={{ padding: "20px" }}>
            <h2 style={{ fontSize: "2.5rem", color: "#333" }}>
              Why Choose Us?
            </h2>
            <p style={{ fontSize: "1.2rem", color: "#666" }}>
              With years of experience and a dedicated team of professionals, we
              provide reliable roofing solutions tailored to your needs. From
              small repairs to large installations, we do it all with precision
              and care.
            </p>
            <LinkContainer to="/about">
              <Button variant="primary">Learn More About Us</Button>
            </LinkContainer>
          </Col>
          <Col md={6}>
            <Image
              src={fullReplacement}
              alt="About Us"
              fluid
              style={{ borderRadius: "12px" }}
            />
          </Col>
        </Row>
      </Card>

      {/* Service Area with Map */}
      <Card
        className="map-section"
        style={{
          marginTop: "50px",
          padding: "30px",
          borderRadius: "12px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        }}
      >
        <h2 style={{ textAlign: "center", fontSize: "2.5rem", color: "#444" }}>
          Our Service Area
        </h2>
        <Row>
          <Col>
            <Wrapper
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              render={renderMapStatus}
            >
              <MyMapComponent />
            </Wrapper>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};

export default HomeScreen;

// import { CardBody, Container, Row } from "react-bootstrap";
// import { Col } from "react-bootstrap";
// import { Card, Button } from "react-bootstrap";
// // import MapContainer from "../components/MapBackend";
// import { LinkContainer } from "react-router-bootstrap";
// import { Nav } from "react-bootstrap";
// import Image from "react-bootstrap/Image";
// import MyMapComponent from "../components/Map";
// import Table from "react-bootstrap/Table";
// import commercialRoofing from "../assets/images/servicespage/commercialRoofing 2.jpeg";
// import residentialRoofing from "../assets/images/servicespage/residentialRoofing.jpeg";
// import epdmRoofing from "../assets/images/servicespage/epdmRubberizedRoofing.jpeg";
// import torchDownRoofing from "../assets/images/servicespage/torchDownFlatRoofing.jpeg";
// import generalRoofingInfo from "../assets/images/servicespage/generalRoofingInformation.png";
// import fullReplacement from "../assets/images/servicespage/fullReplacement 2.jpeg";
// import torchDownFlatRoofing from "../assets/images/servicespage/torchDownFlatRoofing 2.jpeg";
// import generalRoofingInformation from "../assets/images/servicespage/mainPhoneSize.png";
// // import { useState, useMemo } from "react";
// // import BusinessProfile from "../components/BusinessProfile";
// // import GoogleSignIn from "../components/GoogleSignIn";
// // import GoogleProfile from "../components/GoogleProfile";

// import Spinner from "../components/Spinner";
// import ErrorComponent from "../components/ErrorComponent";

// import { Wrapper, Status } from "@googlemaps/react-wrapper";
// import { Link } from "react-router-dom";
// // import PhoneCallButton from "../components/PhoneCallButton";
// const render = (status) => {
//   switch (status) {
//     case Status.LOADING:
//       return <Spinner />;
//     case Status.FAILURE:
//       return <ErrorComponent />;
//     case Status.SUCCESS:
//       return <MyMapComponent />;
//     default:
//       return <MyMapComponent />;
//   }
// };

// const HomeScreen = () => {
//   return (
//     <div className="homepageMainDiv">
//       {/* ///////////////////////////////////////////////////////////////// */}
//       {/* //////////////////////// Section 1 /////////////////////////////// */}
//       {/* ///////////////////////////////////////////////////////////////// */}
//       <Card>
//         <Card.Body>
//           <hr className="sloganTxt"></hr>
//           <h1 className="center">
//             <span className="sloganTxt">
//               One of Grosse Pointe Michigan's Top Roofing Companies
//             </span>
//             <span className="whiteSmoke">:</span>{" "}
//             <span className="highlightRed">Pointe Roofing Improvements</span>
//           </h1>
//           <hr className="sloganTxt"></hr>

//           <Row>
//             <Col>
//               <p className="pText">
//                 {/* <span> </span> Family owned, Professional Pointe Roofing with
//                 Brighter Side Solutions has maintained its reputation for our
//                 attentive and personable relativeness, remaining aware of all
//                 our clients roofing and home improvement needs with ideal
//                 professionalism. Created with you, your home and your happiness
//                 in mind, we work hard, helping our clients achieve their roofing
//                 and home improvement projects. You can call us or feel free
//                 leaving us a message and we will schedule your free estimate at
//                 your earliest convenience. Our contractors work with residential
//                 clients and commercial business owners, offering first-class
//                 roofing services, roof replacement and roof repair. Interested?{" "} */}
//                 <span></span> Welcome to Pointe Roofing Improvements, where
//                 quality craftsmanship and integrity come together to bring your
//                 plans and projects to life! Our approach is simple: Honest and
//                 straight forward, reliable and reasonable. When working with our
//                 team, you’ll receive clear communication, a detailed project
//                 plan, and a timeline that works best. To begin the process,
//                 simply reach out via phone or our website to schedule a
//                 consultation. During this initial meeting, we'll assess your
//                 needs, discuss your vision, and provide a comprehensive
//                 estimate. From there, we’ll guide you through each step
//                 establishing your confidence in our process until we reach
//                 completion. At Pointe Roofing Improvements, we don’t just build
//                 homes—we build trust. Let’s start putting your plans into action
//                 today! <span></span>
//                 <a href="tel:+1-586-360-8447">
//                   <span className="highlightRed">Get a free estimate </span>
//                 </a>
//                 with our roofers in Grosse Pointe, MI.
//               </p>
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>

//       {/* ///////////////////////////////////////////////////////////////// */}
//       {/* //////////////////////// Section 2 /////////////////////////////// */}
//       {/* ///////////////////////////////////////////////////////////////// */}

//       <Card>
//         <Card.Body>
//           <Card.Title className="sloganTxt">
//             Servicing the Grosse Pointe Area
//           </Card.Title>
//           <h6 className="italian">A reliable contractor you can count on:</h6>
//           {/* <Card.Text></Card.Text> */}
//           <Row>
//             <Col>
//               <div className="pText">
//                 For roof repair, roof replacement, new roof installation, or
//                 roofing maintenance and inspection solutions, you can count on
//                 Pointe Roofing Improvements. We offer expert professional
//                 roofing services for commercial and residential customers in the
//                 Grosse Pointes and surrounding communities, and our unmatched
//                 roofing services add both beauty and durability to your
//                 structure. At Pointe Roofing Improvements, we provide our
//                 customers with the best quality roofing and building services.
//                 We have been offering the best customer service since we began
//                 operating. We perform proven roofing solutions that will
//                 withstand time and ensure the protection of your family’s most
//                 valuable asset – your home.
//               </div>
//               {/* These are the icons from font awesome */}

//               <Container className="iconContainer">
//                 <Row>
//                   <Col>
//                     <div>
//                       <i className="fa-solid fa-house-damage fa-xl"></i>
//                     </div>
//                   </Col>
//                   <Col>
//                     <div>
//                       <i className="fa-solid fa-arrow-alt-circle-right fa-xl"></i>
//                     </div>
//                   </Col>
//                   <Col>
//                     <div>
//                       <i className="fa-solid fa-home major fa-xl "></i>
//                     </div>
//                   </Col>
//                 </Row>
//               </Container>
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>

//       {/* ///////////////////////////////////////////////////////////////// */}
//       {/* //////////////////////// Section 3 /////////////////////////////// */}
//       {/* ///////////////////////////////////////////////////////////////// */}

//       <div style={{ margin: "0 auto", display: "grid" }}>
//         <Card className="content">
//           <Card.Title className="sloganTxt">Services</Card.Title>
//           <h6 className="italian">All general exterior home improvements:</h6>
//           <CardBody style={{ margin: "0 auto" }}>
//             <Row>
//               <Col>
//                 <Row>
//                   <Button variant="outline-light" className="btnService">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{
//                       maxWidth: "275px",
//                       margin: "2% auto",
//                       marginBottom: "5%",
//                     }}
//                   />
//                 </Row>

//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{
//                       maxWidth: "275px",
//                       margin: "2% auto",
//                       marginBottom: "5%",
//                     }}
//                   />
//                 </Row>
//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{
//                       maxWidth: "275px",
//                       margin: "2% auto",
//                       marginBottom: "5%",
//                     }}
//                   />
//                 </Row>
//               </Col>

//               <Col>
//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{
//                       maxWidth: "275px",
//                       margin: "2% auto",
//                       marginBottom: "5%",
//                     }}
//                   />
//                 </Row>

//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{
//                       maxWidth: "275px",
//                       margin: "2% auto",
//                       marginBottom: "5%",
//                     }}
//                   />
//                 </Row>

//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{
//                       maxWidth: "275px",
//                       margin: "2% auto",
//                       marginBottom: "5%",
//                     }}
//                   />
//                 </Row>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </div>

//       {/* <Card className="content">
//         <Card.Body>
//           <Card.Title className="sloganTxt">Services</Card.Title>
//           <h6 className="italian">All general exterior home improvements:</h6>

//           <Container className="servicesLinks">
//             <Row className="row2">
//               <Col>
//                 <Nav className="ms-auto">
//                   <LinkContainer to="/services">
//                     <Nav.Link>Full Replacement</Nav.Link>
//                   </LinkContainer>
//                   <LinkContainer to="/services">
//                     <Nav.Link>Gutter Replacement</Nav.Link>
//                   </LinkContainer>
//                   <LinkContainer to="/services">
//                     <Nav.Link>Residential Roofing</Nav.Link>
//                   </LinkContainer>
//                   <LinkContainer to="/services">
//                     <Nav.Link>Commercial Roofing</Nav.Link>
//                   </LinkContainer>
//                   <LinkContainer to="/services">
//                     <Nav.Link>EPDM Rubberized Roofing</Nav.Link>
//                   </LinkContainer>
//                   <LinkContainer to="/services">
//                     <Nav.Link>Torch Down Flat Roofing</Nav.Link>
//                   </LinkContainer>
//                   <LinkContainer to="/services">
//                     <Nav.Link>Shingle Roof</Nav.Link>
//                   </LinkContainer>
//                   <LinkContainer to="/services">
//                     <Nav.Link>Roof Repair</Nav.Link>
//                   </LinkContainer>
//                   <LinkContainer to="/services">
//                     <Nav.Link> Gutter Replacement</Nav.Link>
//                   </LinkContainer>
//                   <LinkContainer to="/services">
//                     <Nav.Link>Gutter Repair</Nav.Link>
//                   </LinkContainer>
//                   <LinkContainer to="/services">
//                     <Nav.Link>Gutter Guards</Nav.Link>
//                   </LinkContainer>
//                 </Nav>
//               </Col>
//             </Row>
//           </Container>

//           <Card.Text></Card.Text>
//         </Card.Body>
//       </Card> */}

//       {/* ///////////////////////////////////////////////////////////////// */}
//       {/* //////////////////////// Section 4 with MAP /////////////////////////////// */}
//       {/* ///////////////////////////////////////////////////////////////// */}

//       <Card className="content">
//         <Card.Body className="Map">
//           <Card.Title className="sloganTxt"> Service Area</Card.Title>
//           {/* <Card.Text></Card.Text> */}
//           <Row>
//             <Col className="actualMap">
//               {/* <MapContainer /> */}
//               {/* <MyMapComponent /> */}

//               <Wrapper
//                 apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
//                 render={render}
//               >
//                 <MyMapComponent />
//               </Wrapper>
//             </Col>
//           </Row>
//           <Row>
//             <Col>
//               {/* <BusinessProfile />
//               <GoogleProfile />
//               <GoogleSignIn /> */}
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>

//       <Card.Title>
//         {" "}
//         <Button href="tel:+1-586-360-8447" variant="danger" className="center">
//           <i className="fa-solid fa-phone"> </i> (586)360-8447
//         </Button>
//         <Link></Link>
//       </Card.Title>

//       {/* <PhoneCallButton
//         onClick={() => {
//           window.location.href = "tel:313-410-6869";
//         }}
//       /> */}
//       {/* ///////////////////////////////////////////////////////////////// */}
//       {/* //////////////////////// Section 5 with Table /////////////////////////////// */}
//       {/* ///////////////////////////////////////////////////////////////// */}
//       {/* <div style={{ margin: "0 auto", display: "grid" }}>
//         <Card className="content">
//           <CardBody style={{ margin: "0 auto" }}>
//             <Row>
//               <Col>
//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{ maxWidth: "275px", margin: "2% auto" }}
//                   />
//                 </Row>

//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{ maxWidth: "275px", margin: "2% auto" }}
//                   />
//                 </Row>
//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{ maxWidth: "275px", margin: "2% auto" }}
//                   />
//                 </Row>
//               </Col>

//               <Col>
//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{ maxWidth: "275px", margin: "2% auto" }}
//                   />
//                 </Row>

//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{ maxWidth: "275px", margin: "2% auto" }}
//                   />
//                 </Row>

//                 <Row>
//                   <Button variant="outline-light">
//                     <LinkContainer to="/services">
//                       <Nav.Link>Full Replacement</Nav.Link>
//                     </LinkContainer>
//                   </Button>
//                   <Image
//                     src={commercialRoofing}
//                     style={{ maxWidth: "275px", margin: "2% auto" }}
//                   />
//                 </Row>
//               </Col>
//             </Row>
//           </CardBody>
//         </Card>
//       </div> */}

//       <br></br>
//       <br></br>
//       <br></br>
//       <br></br>
//     </div>
//   );
// };

// export default HomeScreen;
